import React from 'react'
import Image from 'next/image'

type Props = {
  className?: string
}

export const MainBlockPressLogoImage = ({ className }: Props) => (
  <div className={className}>
    <Image
      src="/img/press/atm-logo@3x.png"
      width="320"
      height="113"
      alt="ATM.com Logo"
    />
  </div>
)
