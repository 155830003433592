import clsx from 'clsx'
import { Container } from '@ui/components/Container'
import { Button } from '@ui/components/buttons/Button'

type Props = {
  className?: string
  children?: React.ReactNode
}

export const MainBlockPressMediaAssets = ({ className, children }: Props) => {
  return (
    <div className={clsx('bg-off-white py-20', className)}>
      <Container>
        <div className="flex flex-col items-center justify-center gap-8 lg:flex-row">
          <div className="flex flex-col items-center gap-4 lg:w-1/2 lg:items-start">
            <h2 className="font-montserrat h2 text-center lg:w-full lg:text-left">
              Media Assets
            </h2>
            <p className="body-2 text-center lg:w-full lg:text-left">
              Official ATM.com photos, logos, and assets available for download.
            </p>
            <div className="py-8">
              <Button
                variant="blueberryLg"
                href="https://drive.google.com/drive/folders/1ghYH1u9_xtRujnAcXCBwVty2_k4MKr-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download
              </Button>
            </div>
          </div>
          <div className="flex items-center justify-center lg:w-1/2">
            {children}
          </div>
        </div>
      </Container>
    </div>
  )
}
