import React from 'react'
import Image from 'next/image'

type Props = {
  className?: string
  name: string
}

export function MainBlockAboutHeadshotsImage({ className, name }: Props) {
  return (
    <div className={className}>
      <Image
        src={`/img/2022q3/main-blocks/about-headshots/${name}@3x.png`}
        width="176"
        height="176"
        alt={name}
      />
    </div>
  )
}
