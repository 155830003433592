import { Container } from '@ui/components/Container'
import clsx from 'clsx'

type Props = {
  className?: string
  children?: React.ReactNode
}

export const MainBlockAboutEmpowering = ({ className, children }: Props) => (
  <div className={clsx('bg-white py-20', className)}>
    <Container>
      <div className="flex flex-col items-center gap-12 lg:flex-row">
        <div className="order-2 flex flex-col gap-8 lg:order-1 lg:w-2/3">
          <h2 className="font-montserrat h2">
            Empowering You to Take Control of Your Finances
          </h2>
          <p className="body-2">
            At ATM.com, we believe financial well-being should be accessible to
            everyone. We understand that unexpected expenses, limited access to
            traditional financial tools, and the desire to earn extra income are
            common challenges. That's why we've built an all-in-one finance app
            that empowers you to earn extra cash, budget wisely, and access
            funds when you need them most.
          </p>
          <p className="body-2">
            Committed to transparency and fairness, we offer innovative
            solutions without credit checks or hidden fees. Our platform puts
            you in control of your financial future, providing budgeting
            insights, convenient cash advances, and opportunities to boost your
            income through rewards and cashback offers. Together, we're building
            a more inclusive financial landscape where financial peace of mind
            is a reality for all.
          </p>
        </div>
        <div className="order-1 flex flex-row items-center justify-center lg:order-2">
          {children}
        </div>
      </div>
    </Container>
  </div>
)
