import React from 'react'
import Image from 'next/image'

type Props = {
  className?: string
}

export const MainBlockJoinColonyImage = ({ className }: Props) => (
  <div className={className}>
    <Image
      src="/img/2022q3/main-blocks/join-colony/desktop@3x.png"
      width="819"
      height="418"
      alt="Congratulations! You are now a member of the ATM colony."
    />
  </div>
)
