import React from 'react'
import clsx from 'clsx'
import { Container } from '@ui/components/Container'
import { Button } from '@ui/components/buttons'

type Props = {
  className?: string
  children?: React.ReactNode
  h1?: React.ReactNode
  h2?: React.ReactNode
  platform?: 'ios' | 'android' | 'both'
  ctaUrl?: string
}

export const MainBlockJoinColony = ({
  className,
  children,
  h1,
  h2,
  ctaUrl = '/sign-up',
}: Props) => (
  <div className={clsx('bg-blueberry-900 text-white', className)}>
    <Container>
      <div className="flex flex-col justify-between gap-8 lg:flex-row">
        <div className="flex flex-col items-center justify-center gap-4 py-20 lg:w-1/2 lg:items-start">
          <h2 className="font-montserrat h2">{`${h1 || 'Get the ATM app!'}`}</h2>
          <h3 className="h5">{`${
            h2 ||
            'Earn cash rewards, budget smarter, and access cash when you need it most.'
          }`}</h3>
          <Button variant="whiteLg" href={ctaUrl} className="border-none">
            Get Started
          </Button>
        </div>
        <div className="flex items-end lg:w-1/2">{children}</div>
      </div>
    </Container>
  </div>
)
