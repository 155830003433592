import React from 'react'
import Image from 'next/image'

type Props = {
  className?: string
}

export const HomepageScreenshotsImage = ({ className }: Props) => (
  <div className={className}>
    <Image
      src="/img/2022q3/hero/homepage/desktop_v2@3x.png"
      width="500"
      height="472"
      alt="ATM app with ant"
    />
  </div>
)

export const HomepagePersonalFinanceImage = ({ className }: Props) => (
  <div className={className}>
    <Image
      src="/img/personal-finance@3x.png"
      width="611"
      height="373"
      alt="ATM Personal Finance"
    />
  </div>
)

export const HomepageATMInstantCashRewardsImage = ({ className }: Props) => (
  <div className={className}>
    <Image
      src="/img/atm-instant-cash-rewards@3x.jpg"
      width="714"
      height="546"
      alt="ATM Instant Cash & Rewards"
    />
  </div>
)
