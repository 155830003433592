type MetaProps = {
  title: string
  description: string
  canonical: string
  image?: string
  imageAlt?: string
  imageWidth?: string
  imageHeight?: string
}

export function Meta({
  title,
  description,
  canonical,
  image = '/img/og/og-atm-3.png',
  imageAlt = '',
  imageWidth = '1024',
  imageHeight = '500',
}: MetaProps) {
  return (
    <>
      <link rel="icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" href="/favicon-192x192.png" />
      <link rel="manifest" href="/manifest.json" />
      <link rel="icon" href="/favicon.ico" />
      <link
        rel="icon"
        type="image/png"
        href="/favicon-16x16.png"
        sizes="16x16"
      />
      <link
        rel="icon"
        type="image/png"
        href="/favicon-32x32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href="/favicon-64x64.png"
        sizes="64x64"
      />
      <link
        rel="icon"
        type="image/png"
        href="/favicon-96x96.png"
        sizes="96x96"
      />
      <link
        rel="icon"
        type="image/png"
        href="/favicon-128x128.png"
        sizes="128x128"
      />
      <link
        rel="icon"
        type="image/png"
        href="/favicon-180x180.png"
        sizes="180x180"
      />
      <link
        rel="icon"
        type="image/png"
        href="/favicon-192x192.png"
        sizes="192x192"
      />
      <link
        rel="icon"
        type="image/png"
        href="/favicon-256x256.png"
        sizes="256x256"
      />
      <link
        rel="icon"
        type="image/png"
        href="/favicon-512x512.png"
        sizes="512x512"
      />
      <link
        rel="icon"
        type="image/png"
        href="/favicon-1024x1024.png"
        sizes="1024x1024"
      />
      <link rel="canonical" href={canonical} />
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <meta name="image:alt" content={imageAlt} />
      <meta name="image:width" content={imageWidth} />
      <meta name="image:height" content={imageHeight} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@atmdotcom" />
      <meta name="twitter:creator" content="@atmdotcom" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:image:alt" content={imageAlt} />
      <meta name="twitter:image:width" content={imageWidth} />
      <meta name="twitter:image:height" content={imageHeight} />
    </>
  )
}
