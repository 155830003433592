import { Container } from '@ui/components/Container'
import clsx from 'clsx'

type Props = {
  className?: string
  children?: React.ReactNode
}

export const MainBlockRewards = ({ className, children }: Props) => {
  return (
    <div id="rewards" className={clsx('bg-off-white py-20', className)}>
      <Container>
        <div className="flex flex-col gap-8 lg:flex-row">
          <div className="flex flex-col gap-4 lg:w-1/2">
            <h2 className="font-montserrat h2">Rewards & Cashback</h2>
            <p className="body-2 text-dolphin-1000">
              At ATM.com, we make it easy for you to earn extra cash while
              managing your finances. Discover the rewarding opportunities that
              fit seamlessly into your life:
            </p>
            <ul className="body-2 text-dolphin-1000 list-disc space-y-6 pl-5">
              <li>
                <strong>Game Rewards</strong>
                <br /> Turn your gaming time into real money. Play popular games
                like Coin Master, Solitaire, and more, with the potential to
                earn up to $250 a month. It's fun, easy, and rewarding.
              </li>
              <li>
                <strong>Paid Surveys</strong>
                <br /> Share your opinions and earn! With quick and easy surveys
                that pay between $0.03 and $5.00 each, it's a simple and
                consistent way to boost your income. New surveys are added
                weekly.
              </li>
              <li>
                <strong>Cashback Offers</strong>
                <br /> Save money on your everyday purchases with cashback from
                over 500 verified partners. Whether you're buying groceries,
                fashion, or electronics, earn cashback effortlessly and grow
                your savings.
              </li>
              <li>
                <strong>Location Rewards</strong>
                <br /> Earn money passively by enabling location sharing. As you
                go about your day, you'll earn cash rewards just for visiting
                stores, making it one of the easiest ways to increase your
                earnings.
              </li>
              <li>
                <strong>Daily Rewards</strong>
                <br /> Get rewarded daily by answering a few quick questions. It
                takes less than a minute, and nearly a million users are already
                benefiting from this convenient daily earning opportunity.
              </li>
            </ul>
            <p className="body-2 text-dolphin-1000">
              ATM.com is designed to help you earn and save in ways that fit
              seamlessly into your lifestyle. Download the app today and start
              making the most of your time and money!
            </p>
          </div>
          <div className="flex-auto lg:w-1/2">{children}</div>
        </div>
      </Container>
    </div>
  )
}
