import React from 'react'
import Image from 'next/image'

type Props = {
  className?: string
}

export const MainBlockInstantCashImage = ({ className }: Props) => (
  <div className={className}>
    <Image
      src="/img/2024q3/main-blocks/ant-with-card@3x.png"
      width="555"
      height="416"
      alt="ATM Instant Cash $50"
    />
  </div>
)

export const MainBlockInstantCashImage2 = ({ className }: Props) => (
  <div className={className}>
    <Image
      src="/img/atm-instant-cash-50@3x.png"
      width="608"
      height="469"
      alt="ATM Instant Cash $50"
    />
  </div>
)

export const MainBlockTrackYourSpendingImage = ({ className }: Props) => (
  <div className={className}>
    <Image
      src="/img/track-spending-screenshot@3x.png"
      width="323"
      height="547"
      alt="Track your spending"
    />
  </div>
)
