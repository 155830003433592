import React from 'react'
import Image from 'next/image'

type Props = {
  className?: string
}

export const MainBlockAboutWhoWeAreImage = ({ className }: Props) => (
  <div className={className}>
    <Image
      src="/img/2022q3/main-blocks/about-who-we-are/desktop@3x.jpg"
      width="505"
      height="302"
      alt="ATM team"
    />
  </div>
)
