import React from 'react'
import Image from 'next/image'

type Props = {
  className?: string
}

export const MainBlockAboutEmpoweringImage = ({ className }: Props) => (
  <div className={className}>
    <Image
      src="/img/2022q3/main-blocks/about-empowering/desktop@3x.png"
      width="881"
      height="604"
      alt="Ants raining money"
    />
  </div>
)
