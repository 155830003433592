import React from 'react'
import clsx from 'clsx'
import { Container } from '@ui/components/Container'
import { Button } from '@ui/components/buttons'
import Image from 'next/image'

type Props = {
  className?: string
  h1?: React.ReactNode
  h2?: React.ReactNode
  platform?: 'ios' | 'android' | 'both'
  ctaText?: string
  ctaUrl: string
}

export const MainBlockJoinMillionsDownload = ({
  className,
  ctaText = 'Sign Up',
  ctaUrl,
}: Props) => (
  <div
    className={clsx(
      'main-block-join-millions-download bg-blueberry-1000 relative text-white',
      className,
    )}
  >
    <Container className="relative z-10">
      <div className="flex min-h-[336px] flex-col items-center justify-center gap-8">
        <h2 className="h2 font-montserrat mx-auto max-w-2xl text-center">
          Join millions of users and take control of your finances.
        </h2>
        <Button variant="whiteLg" href={ctaUrl} className="border-none">
          {ctaText}
        </Button>
      </div>
    </Container>
    <Image
      src="/img/bg-blue-money@3x.jpg"
      alt="Money background"
      fill={true}
      className="object-cover object-center"
    />
  </div>
)
