import clsx from 'clsx'
import Script from 'next/script'
import { Container } from '@ui/components/Container'
import { Spinner } from '@ui/components/Spinner'

type Props = {
  className?: string
}

export const MainBlockCareersContainer = ({ className }: Props) => (
  <div className={clsx('main-block-careers-container py-20', className)}>
    <Container>
      <div className="flex flex-col items-start justify-center gap-4">
        <h2 className="font-montserrat h2">Job Openings</h2>
        <div id="whr_embed_hook">
          <Spinner className="h-10 w-10 text-black" />
        </div>
      </div>
    </Container>
    <Script src="/workable.js" type="text/javascript" />
    <style jsx>{`
      #whr_embed_hook {
        text-align: center;
      }
    `}</style>
  </div>
)
