import React from 'react'
import Image from 'next/image'
import clsx from 'clsx'
import { Container } from '@ui/components/Container'
import { Button } from '@ui/components/buttons/Button'
import { InstantCashDisclosure } from '@ui/components/Disclosure'

type Variant =
  | 'default'
  | 'off-white'
  | 'white'
  | 'blueberry-200'
  | 'blueberry-700'

interface Props {
  className?: string
  variant?: Variant
  title?: string
  description?: string
  cta?: string
  handleClickEvent?: () => void
  hideDescription?: boolean
  customDescription?: React.ReactNode
}

const variantClasses: Record<Variant, string> = {
  default: 'bg-off-white text-black',
  'off-white': 'bg-off-white text-black',
  white: 'bg-white text-black',
  'blueberry-200': 'bg-blueberry-200 text-black',
  'blueberry-700': 'bg-blueberry-700 text-white',
}

export const BottomCallToAction: React.FC<Props> = ({
  className,
  variant = 'default',
  title,
  description,
  cta,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleClickEvent = () => {},
  hideDescription = false,
  customDescription,
}) => {
  return (
    <div className={clsx(variantClasses[variant], className)}>
      <Container>
        <div className="flex flex-col items-center md:flex-row md:items-end">
          <div className="self-center px-4 pt-12 md:w-2/3 md:py-12">
            <h3 className="font-montserrat text-3xl font-bold sm:text-4xl md:text-5xl">
              {title || (
                <>
                  ATM <span className="whitespace-pre">Instant Cash™</span>
                </>
              )}
            </h3>
            {!hideDescription && (
              <p className="mt-4 text-2xl font-semibold md:mt-6 md:text-2xl">
                {description ||
                  'Get up to $50*, no interest, no credit check, no late fees.'}
              </p>
            )}
            {customDescription}
            <InstantCashDisclosure className="mb-4 mt-1 md:mb-6" />
            <div className="flex">
              <Button
                onClick={handleClickEvent}
                variant={
                  ['blueberry-700'].includes(variant)
                    ? 'whiteLg'
                    : 'blueberryLg'
                }
                href="/auth/register"
              >
                {cta || <>Get Started</>}
              </Button>
            </div>
          </div>
          <div className="mt-8 flex items-end justify-center">
            <Image
              alt="ATM app"
              src="/img/bottom_desktop@3x.png"
              width={819}
              height={418}
            />
          </div>
        </div>
      </Container>
    </div>
  )
}
