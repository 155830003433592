import React from 'react'
import Image from 'next/image'

type Props = {
  className?: string
}

export const MainBlockPressPublicationsTechCrunch = ({ className }: Props) => (
  <div className={className}>
    <Image
      src="/img/press/banner/techcrunch-logo-white@3x.png"
      width="213"
      height="29"
      alt="TechCrunch"
    />
  </div>
)

export const MainBlockPressPublicationsLosAngelesBusinessJournal = ({
  className,
}: Props) => (
  <div className={className}>
    <Image
      src="/img/press/banner/labusinessjournal@3x.png"
      width="333"
      height="29"
      alt="LA Business Journal"
    />
  </div>
)

export const MainBlockPressPublicationsBuiltInLA = ({ className }: Props) => (
  <div className={className}>
    <Image
      src="/img/press/banner/builtinla@3x.png"
      width="72"
      height="29"
      alt="Built in LA"
    />
  </div>
)

export const MainBlockPressPublicationsOrangeCountyBusinessJournal = ({
  className,
}: Props) => (
  <div className={className}>
    <Image
      src="/img/press/banner/ocbj-2@3x.png"
      width="388"
      height="29"
      alt="Orange County Business Journal"
    />
  </div>
)
