import { Container } from '@ui/components/Container'
import clsx from 'clsx'
import React from 'react'

type Props = {
  className?: string
  HeadshotComponent: React.ComponentType<{ name: string; className?: string }>
}

type PersonProps = {
  name: string
  fullName: string
  title: string
}

const team = [
  {
    name: 'Walter',
    fullName: 'Walter Cruttenden',
    title: 'Co-Founder',
  },
  {
    name: 'Michael',
    fullName: 'Michael Gleason',
    title: 'Co-Founder',
  },
  {
    name: 'BJ',
    fullName: 'BJ Donovan',
    title: 'CEO',
  },
  {
    name: 'Bill',
    fullName: 'Bill Topaz',
    title: 'CAO/CCO',
  },
  {
    name: 'Shital',
    fullName: 'Shital Patel',
    title: 'CFO',
  },
]

export function MainBlockAboutMeetTheTeam({
  className,
  HeadshotComponent,
}: Props) {
  return (
    <div className={clsx('bg-off-white py-20', className)}>
      <Container>
        <div className="flex flex-col items-center gap-8">
          <h2 className="font-montserrat h2">Meet the team</h2>
          <div className="flex flex-row flex-wrap items-center justify-center gap-12">
            {team.map((person: PersonProps) => (
              <div
                className="flex flex-col items-center gap-4"
                key={person.fullName}
              >
                <HeadshotComponent className="headshot" name={person.name} />
                <div className="h6 text-center">{person.fullName}</div>
                <div className="body-2 text-center">{person.title}</div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  )
}
