import Link from 'next/link'
import Image from 'next/image'

export type PressLinkListProps = {
  title: string
  description: string
  link: string
  date: string
  publication?: string
  img?: string
}

export const PressLinkList = (source: Array<PressLinkListProps>) => (
  <ul className="flex list-none flex-col gap-20">
    {source.map((m: PressLinkListProps) => (
      <li key={m.title} className="flex flex-col gap-2">
        {m.img && m.publication && (
          <div className="h6 flex flex-row items-center gap-3">
            <Image src={m.img} alt={m.publication} width={42} height={42} />
            {m.publication}
          </div>
        )}
        <h3 className="h4">
          <Link target="_blank" href={m.link} rel="noreferrer">
            {m.title}
          </Link>
        </h3>
        <h6 className="body-3 text-dolphin-900">{m.date}</h6>
        <div className="body-2">{m.description}</div>
        <Link
          className="body-3 text-blueberry-700"
          href={m.link}
          target="_blank"
          rel="noreferrer"
        >
          Read More
        </Link>
      </li>
    ))}
  </ul>
)
